import * as React from "react";
import PropTypes from "prop-types";
import { withPreviewResolver } from "gatsby-source-prismic";

import Layout from "../components/layout";
import { previewLinkResolver } from "../config/linkResolver";

const PreviewPage = ({ isPreview }) => {
  if (isPreview === false) return "Not a preview!";

  return (
    <Layout headerData={{}} footerData={{}}>
      <p>Loading</p>
    </Layout>
  );
};

PreviewPage.propTypes = {
  isPreview: PropTypes.bool,
};

export default withPreviewResolver(PreviewPage, {
  repositoryName: "par-mk2-institut",
  linkResolver: previewLinkResolver,
});
